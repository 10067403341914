import React, { useEffect, useState } from 'react'
import './AIMatrix.css'
import { useAlert } from 'react-alert';
import { getAIMatrixData, getTrackWatch, updateTracklist } from '../../Actions/Matrix';
import DatePicker from "react-datepicker";
import Select from 'react-select'
import { getMatrixData } from '../../Actions/BuySell';


const AIMatrix = ({ setAIMatrixOpen }) => {
  const alert = useAlert();
  const [search, setSearch] = useState("");
  const [trend, setTrend] = useState('positive');
  const [duration, setDuration] = useState('daily');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("No Data Found");
  const [fromDate, setFromDate] = useState(new Date());
  const [watchPortfolio, setWatchPortfolio] = useState([]);
  const [watchLoading, setWatchLoading] = useState(false);
  const [symbolList,setSymbolList] = useState([]);
  const [trackSymbols,setTrackSymbols] = useState([]);
  const handleSearchSymbol = (e) => {
    setSearch(e.target.value);
  }
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [showSelectedSymbol, setShowSelectedSymbol] = useState(false);
  const [selectedSymbolData, SetSelectedSymbolData] = useState("");


  const getData = async () => {
    let data = {};
    if (search) {
      if (search.length <= 1) {
        alert.error("Please select the proper symbol")
        return
      }
      data = { symbol: search.toUpperCase() }
    }
    data = {
      ...data,
      trend,
      pattern_type: duration,
      startDate: fromDate
    }
    setLoading(true);
    setData([])
    setMessage("")
    const res = await getAIMatrixData(data);
    setLoading(false);
    if (res.success) {
      if (res.data.length === 0) {
        setMessage("No Data Found");
      }
      setData(res.data);
    } else {
      // alert.error(res.message);
      setMessage(res.message);
    }
  }

  const getTrackData = async () => {
    const data = {customDate:fromDate}
    setWatchLoading(true)
    const res = await getTrackWatch(data);
    setWatchLoading(false);
    if (res.success) {
      setWatchPortfolio(res.data);
    } else {
      setWatchPortfolio([]);
    }

  }

  useEffect(() => {
    getData();
  }, [duration, trend, fromDate])

  useEffect(() => {
    getTrackData();
  }, [fromDate]);

  useEffect(() => {
    const getData = async () => {
        const res = await getMatrixData();
        if(res.status && res.symbol){
            setSymbolList(res.symbol.map(ele => ({ label: ele, value: ele })));
        }
    }
    getData();
  }, [])

  const handleSelectSymbol = (item) => {
    setSelectedSymbol(item);
    SetSelectedSymbolData(item.resultButttonData);
    setShowSelectedSymbol(true)
  }

  const handleSelectWatchPattern = (symbol, data) => {
    setSelectedSymbol(symbol);
    // SetSelectedSymbolData(data.sort((a,b) => b.count-a.count));
    SetSelectedSymbolData(data && data.length ? data : []);
    setShowSelectedSymbol(true)
  }

  const handleSaveTrackList = async() => {
    const data = trackSymbols.map(ele => ({symbol:ele.value}));
          const res = await updateTracklist({symols:data});
          if(res.success){
            alert.success("symbols added");
            setTrackSymbols([])
            getTrackData();
          }
      }
  

  return (
    <>
      {showSelectedSymbol &&
        <div className='select-symbol-data'>
          <span className="material-icons close-analysis" onClick={() => { setShowSelectedSymbol(false) }}>close</span>
          <h3 className='c-symbol'>{selectedSymbol?.singleSymbol} <span style={{ color: selectedSymbol.positive ? "green" : "red", paddingLeft: "5px" }} className='aim-percent'>( {selectedSymbol?.perChange ? selectedSymbol?.perChange?.toFixed(2) : ""}% )</span></h3>
          <table className="data-matrix-table">
            <thead>
              <tr className='table-row'>
                <th className="table-head head">Pattern</th>
                <th className="table-head head">Count</th>
              </tr>
            </thead>

            <tbody>
              {selectedSymbolData?.length > 0 && selectedSymbolData.map(ele => {
                return (
                  <tr key={ele._id} className='table-row'>
                    <th className='table-head head holds-row'>{ele.key}</th>
                    <th className='table-head head holds-row'>{ele.count}</th>
                  </tr>
                )
              })
              }
            </tbody>

          </table>


        </div>}





      <div className='ai-matrix'>
        <span className="material-icons close-analysis" onClick={() => setAIMatrixOpen(false)}>close</span>
        <div className='aiMatrix-cont'>
          <h3 className='trends-head'>Trends</h3>
          <div className='aim-search-cont'>
            <input type="text"
              value={search}
              onChange={handleSearchSymbol}
              placeholder='Enter the symbol'
            />
            <button onClick={() => getData()}>Search</button>
          </div>
          <div style={{ display: 'flex', gap: "50px", alignItems: 'center' }}>
            <div className='trend-cont'>
              <input type="radio" name='trend' checked={trend === "positive" ? true : false} onChange={() => setTrend('positive')} /> <label>Positive</label>
              <input style={{ marginLeft: "30px" }} type="radio" name='trend' checked={trend === "negative" ? true : false} onChange={() => setTrend('negative')} /> <label>Negative</label>
              <input style={{ marginLeft: "30px" }} type="radio" name='trend' checked={trend === "pre" ? true : false} onChange={() => setTrend('pre')} /> <label>Pre</label>
            </div>

            <div className='aim-duration'>
              <button className={duration === "daily" ? 'active' : ""} onClick={() => setDuration('daily')}>Daily</button>
              <button className={duration === "weekly" ? 'active' : ""} onClick={() => setDuration('weekly')}>Weekly</button>
              <button className={duration === "monthly" ? 'active' : ""} onClick={() => setDuration('monthly')}>Monthly</button>
            </div>

            <div style={{ height: "20px", width: "100px" }}>
              <DatePicker
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                dateFormat='dd/MM/yyyy'
                wrapperClassName="datamatrix-calender"
                filterDate={date => date.getDay() !== 6 && date.getDay() !== 0}
                // includeDates={Active}
                // minDate={startDate}
                maxDate={new Date()}
                closeOnScroll={true}
                shouldCloseOnSelect={true}
              />
            </div>

          </div>

          {/* <hr className='aim-hr'/> */}
          <div
            className='aim-res-cont'
          >
            <div>
              {loading ? <div>Loading....</div> :
                data.length > 0 ? data.map(ele => {
                  return (
                    <div className='aim-res'>
                      <span >{ele.pattern} </span>
                      <span >{ele.count} </span>
                      <span >{ele.consistentCount}</span>
                      <div className='ai-slist'>
                        {ele?.matchSybols?.length > 0 ? ele.matchSybols.map((item, index) => {
                          return (
                            <span onClick={() => handleSelectSymbol(item)} j style={{ color: item.positive ? "green" : "red", padding: "2px", borderRadius: "6px", cursor: "pointer" }}>{`${item.singleSymbol}${index < ele.matchSybols.length - 1 ? "," : ""}`}</span>
                          )
                        }) : "-"}

                      </div>
                    </div>
                  )
                }) : <div>{message}</div>}
            </div>

            <div>
             <h4 className='pt-watch-head'>TrackList</h4>
             <div className='pt-selct-symbol-cont'>
             <Select
                    value={trackSymbols}
                    onChange={(val) => setTrackSymbols(val)}
                    options={symbolList}
                    isMulti
                    className='result-symbol-select'
                    closeMenuOnSelect={false}
                />
                <button onClick={handleSaveTrackList}>save</button>
             </div>
              
              <table className="pt-table-container">
                <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Daily</th>
                    <th>Weekly</th>
                    <th>Monthly</th>
                  </tr>
                </thead>
                <tbody>
                  {watchLoading ? (
                    <tr className="pt-loading-row">
                      <td colSpan="4">Loading....</td>
                    </tr>
                  ) : watchPortfolio?.length > 0 ? (
                    watchPortfolio.map((ele, index) => (
                      <tr key={index}>
                        <td>{ele.symbol}</td>
                        <td style={{ color: ele.daily.positive ? "green" : "red"}} onClick={() => handleSelectWatchPattern({ singleSymbol: ele.symbol, perChange: ele?.daily?.pChange, positive: ele.daily.positive }, ele.daily.data)}>{ele?.daily?.data?.map(d => d.key)?.join('')}</td>


                        <td style={{ color: ele.weekly.positive ? "green" : "red"}} onClick={() => handleSelectWatchPattern({ singleSymbol: ele.symbol, perChange: ele.weekly.pChange, positive: ele.weekly.positive }, ele.weekly.data)}>{ele?.weekly?.data?.map(w => w.key)?.join('')}</td>


                        <td style={{ color: ele.monthly.positive ? "green" : "red"}} onClick={() => handleSelectWatchPattern({ singleSymbol: ele.symbol, perChange: ele.monthly.pChange, positive: ele.monthly.positive }, ele.monthly.data)}>{ele?.monthly?.data?.map(m => m.key)?.join('')}</td>
                      </tr>
                    ))

                  ) : (
                    <tr className="pt-no-data-row">
                      <td colSpan="4">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AIMatrix